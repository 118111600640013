import React, {FC} from 'react';
import {Fade, Modal, TextField} from '@material-ui/core';
import {MODALS, useModals} from '../../containers';

export type FeedbackSuccessProps = {
  message: string;
  open: boolean;
};

export const Success: FC<FeedbackSuccessProps> = ({message, open}) => {
  const {closeModal} = useModals();

  const handleClose = (): void => closeModal(MODALS.Success);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}>
      <Fade in={open}>
        <div className="modal-body only-message">
          <div className="modal-content">
            <i onClick={handleClose} className="icon-close" />
            <p>{message}</p>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
