import React, {FC, useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {MODALS, useModals} from 'containers/Modal';
import {Modal} from '../Modal';
import {Button} from '@material-ui/core';
import {schema} from './validator';

export type ForgotPasswordProps = {
  open: boolean;
};

export const ForgotPassword: FC<ForgotPasswordProps> = ({open}) => {
  const [loading, setLoading] = useState(false);
  const {register, handleSubmit, errors} = useForm({
    resolver: yupResolver(schema),
  });
  const {closeModal} = useModals();
  const onSignInClose = useCallback(
    () => closeModal(MODALS.ForgotPassword),
    [],
  );

  const onSubmit = async (payloads: {email: string}): Promise<void> => {
    try {
      setLoading(true);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onSignInClose}>
      <>
        <h2 className="title is-2 mb-64">Forgot password</h2>
        <p className="font-light mb-32">
          We will send you an e-mail to reset your password:
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="sign-form text-center">
          <div className="mb-32">
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              fullWidth>
              Send
            </Button>
          </div>
        </form>
      </>
    </Modal>
  );
};
