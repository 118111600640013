export interface User {
  role: Roles;
  createdAt: string;
  updatedAt: string;
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
}

export enum Roles {
  Admin = 'admin',
  Lead = 'lead',
  Hr = 'hr',
  Sales = 'sales',
  Pm = 'pm',
}

export interface TokenType {
  token_interface: string;
  jwt_token: string;
  expires_in: string;
  refresh_token: string;
  refresh_token_expires_at: string;
}

export interface CreateUserRequestData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface UpdateUserRequestData {
  email: string;
}
export interface LoginRequestData {
  email: string;
  password: string;
}
