import {lazy} from 'react';
import {Pathnames} from './routes';
import {ProtectedRouteType} from './types';
import {Roles} from '../interfaces';
import NotFound from '../layouts/NotFound';
import Home from '../layouts/Home';
import LoginPage from 'layouts/Login';

const AdminFeedbacks = lazy(() => import('layouts/Admin/Feedbacks/Feedbacks'));
const AdminColleagues = lazy(
  () => import('layouts/Admin/Colleagues/ColleaguesList'),
);
const FeedbacksHistory = lazy(() => import('layouts/FeedbacksHistory'));
const ChangePassword = lazy(() => import('layouts/ChangePassword'));

export const Routes: Array<ProtectedRouteType> = [
  {path: Pathnames.login, Component: LoginPage},
  {
    Component: FeedbacksHistory,
    path: Pathnames.feedbacksHistory,
    guard: true,
  },
  {
    Component: AdminFeedbacks,
    path: Pathnames.adminFeedbacks,
    roles: [Roles.Admin, Roles.Hr, Roles.Sales],
    guard: true,
  },
  {
    Component: AdminColleagues,
    path: Pathnames.adminUsers,
    roles: [Roles.Admin, Roles.Hr, Roles.Sales],
    guard: true,
  },
  {Component: Home, path: Pathnames.home, exact: true, guard: true},
  {
    Component: ChangePassword,
    path: Pathnames.changePassword,
    exact: true,
    guard: true,
  },
  {Component: NotFound, path: Pathnames.notFound},
];
