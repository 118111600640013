import {createContext} from 'react';
import {ApolloError} from '@apollo/client';
import {CreateUserRequestData, UpdateUserRequestData} from '../../interfaces';
import {PaginationParams} from '../../http-client/types';

export interface UserContextI {
  readonly State: {
    loading: boolean;
    error?: ApolloError;
    user: any;
    users: Array<any>;
  };
  readonly Actions: {
    onCreateUser: (payload: CreateUserRequestData) => Promise<any> | undefined;
    onUpdateUser: (payload: UpdateUserRequestData) => Promise<any> | undefined;
    onGetUser: (id: string) => Promise<any> | undefined;
    onGetUsers: (payload: PaginationParams) => Promise<any> | undefined;
  };
  readonly Combined: UserContextI['State'] & UserContextI['Actions'];
}
export const UserContext = createContext<UserContextI['Combined']>({
  error: undefined,
  loading: false,
  user: null,
  users: [],

  onCreateUser: () => undefined,
  onUpdateUser: () => undefined,
  onGetUser: () => undefined,
  onGetUsers: () => undefined,
});
