import {gql} from '@apollo/client';

export const LOGIN = gql`
  mutation login($password: String!, $email: String!) {
    login(password: $password, email: $email) {
      token_type
      jwt_token
      #      expires_in
      #      refresh_token
      #      refresh_token_expires_at
    }
  }
`;
