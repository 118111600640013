import React, {FC} from 'react';
import {UserContext, UserContextI} from './UserContext';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import {CREATE_USER} from '../../graphQL/mutations/userCreate';
import {UPDATE_USER} from '../../graphQL/mutations/userUpdate';
import {GET_USER} from '../../graphQL/queries/user';
import {GET_USERS} from '../../graphQL/queries/users';
import {CreateUserRequestData, UpdateUserRequestData} from '../../interfaces';
import {toast} from 'react-toastify';
import {PaginationParams} from '../../http-client/types';
import {GET_COLLEAGUES} from '../../graphQL/queries/colleagues';
import {DEFAULT_PAGINATION} from '../../constants/general';

const UserProvider: FC = ({children}) => {
  const [getUsers, {data: users, loading: usersLoading}] = useLazyQuery(
    GET_USERS,
  );

  const [
    getUser,
    {data: user, loading: userLoading, error: userError},
  ] = useLazyQuery(GET_USER);

  const [userUpdate, {loading: updateLoading}] = useMutation(UPDATE_USER);

  const [userCreate, {loading: createLoading}] = useMutation(CREATE_USER, {
    onCompleted: () => toast.success('Done'),
    onError: (err) => toast.error(err.message),
    update(cache, {data: {userCreate}}) {
      const data = cache.readQuery({
        query: GET_USERS,
        variables: DEFAULT_PAGINATION,
      });

      cache.writeQuery({
        query: GET_COLLEAGUES,
        variables: DEFAULT_PAGINATION,

        // @ts-ignore
        data: {colleagues: [userCreate, ...data?.users]},
      });
    },
  });

  const handleCreateUser = async (
    payload: CreateUserRequestData,
  ): Promise<void> => {
    await userCreate({variables: payload});
  };

  const handleUpdateUser = async (
    payload: UpdateUserRequestData,
  ): Promise<void> => {
    await userUpdate({variables: payload});
  };

  const handleGetUser = async (id: string): Promise<void> =>
    await getUser({variables: {id}});

  const handleGetUsers = async (payload: PaginationParams): Promise<void> => {
    await getUsers({variables: payload});
  };

  const contextValues = (): UserContextI['Combined'] => {
    return {
      user: user,
      users: users?.users,
      loading: userLoading || usersLoading || createLoading || updateLoading,
      error: userError,
      onCreateUser: handleCreateUser,
      onUpdateUser: handleUpdateUser,
      onGetUser: handleGetUser,
      onGetUsers: handleGetUsers,
    };
  };

  return (
    <UserContext.Provider value={contextValues()}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
