import React, {FC} from 'react';
import cx from 'classnames';
import {Modal as MaterialModal, Backdrop, Fade} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  lg?: boolean;
  sm?: boolean;
  xl?: boolean;
  xxl?: boolean;
  withTabs?: boolean;
  centerContent?: boolean;
  withCloseButton?: boolean;
};

export const Modal: FC<ModalProps> = ({
  open,
  onClose,
  lg,
  sm,
  xl,
  xxl,
  children,
  withTabs,
  centerContent,
  withCloseButton,
}) => (
  <MaterialModal
    className={cx('modal-wrapper', {
      'tabs-modal': withTabs,
      'modal-lg': lg,
      'modal-sm': sm,
      'modal-xl': xl,
      'modal-xxl': xxl,
    })}
    open={open}
    onClose={onClose}>
    <Fade in={open}>
      <div className={cx('modal-body', {'text-center': centerContent})}>
        {withCloseButton && (
          <div className="close-modal text-right" onClick={onClose}>
            <CloseIcon />
          </div>
        )}
        {children}
      </div>
    </Fade>
  </MaterialModal>
);

Modal.defaultProps = {
  lg: false,
  withTabs: false,
  centerContent: false,
  withCloseButton: false,
};
