import {Button, Fade, Modal, TextField} from '@material-ui/core';
import React, {FC, useContext} from 'react';
import {useForm} from 'react-hook-form';
import {AuthContext, MODALS, useModals} from '../../containers';
import {yupResolver} from '@hookform/resolvers/yup';
import {addUserValidation} from '../../utils/validation';
import classnames from 'classnames';
import {useMutation} from '@apollo/client';
import {toast} from 'react-toastify';
import {DEFAULT_PAGINATION} from '../../constants/general';
import {GET_USERS} from '../../graphQL/queries/users';
import {CreateUserRequestData} from '../../interfaces';
import {CREATE_USER} from '../../graphQL/mutations/userCreate';

export type AddUserModalProps = {
  open: boolean;
};

export const AddUser: FC<AddUserModalProps> = ({open = false}) => {
  const {register, errors, handleSubmit} = useForm({
    mode: 'onTouched',
    resolver: yupResolver(addUserValidation),
  });
  const {currentUser} = useContext(AuthContext);
  const {closeModal, openModal} = useModals();

  const [userCreate, {loading}] = useMutation(CREATE_USER, {
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      closeModal(MODALS.AddUser);
      openModal<MODALS.Success>(MODALS.Success, {
        message: 'New user was added successfully',
      });
    },
    update(cache, {data: {userCreate}}) {
      const data = cache.readQuery({
        query: GET_USERS,
        variables: DEFAULT_PAGINATION,
      });

      cache.writeQuery({
        query: GET_USERS,
        variables: DEFAULT_PAGINATION,

        // @ts-ignore
        data: {users: [userCreate, ...data?.users]},
      });
    },
  });

  const handleClose = (): void => closeModal(MODALS.AddUser);

  const handleSubmitUser = async ({
    firstName,
    lastName,
    password,
    email,
  }: Partial<CreateUserRequestData>): Promise<void> => {
    await userCreate({
      variables: {
        firstName,
        lastName,
        password,
        email,
        userId: currentUser?.id,
      },
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}>
      <Fade in={open}>
        <div className="modal-body">
          <form onSubmit={handleSubmit(handleSubmitUser)}>
            <div className="modal-header">
              <p>Add New User</p>
              <i onClick={handleClose} className="icon-close" />
            </div>
            <div className="modal-content">
              <div className="modal-form">
                <div className="form-row">
                  <p className="form-label sm">First Name</p>
                  <TextField
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    variant="outlined"
                    inputRef={register}
                    className={classnames({
                      'error-input': !!errors.firstName,
                    })}
                  />
                  {!!errors.firstName && (
                    <p className="subtext error">{errors.firstName.message}</p>
                  )}
                </div>
                <div className="form-row">
                  <p className="form-label sm">Last Name</p>
                  <TextField
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    variant="outlined"
                    inputRef={register}
                    className={classnames({
                      'error-input': !!errors.lastName,
                    })}
                  />
                  {!!errors.lastName && (
                    <p className="subtext error">{errors.lastName.message}</p>
                  )}
                </div>
                <div className="form-row">
                  <p className="form-label sm">Email</p>
                  <TextField
                    id="email"
                    name="email"
                    placeholder="Email"
                    variant="outlined"
                    inputRef={register}
                    className={classnames({
                      'error-input': !!errors.email,
                    })}
                  />
                  {!!errors.email && (
                    <p className="subtext error">{errors.email.message}</p>
                  )}
                </div>
                <div className="form-row">
                  <p className="form-label sm">Password</p>
                  <TextField
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    variant="outlined"
                    inputRef={register}
                    className={classnames({
                      'error-input': !!errors.password,
                    })}
                  />
                  {!!errors.password && (
                    <p className="subtext error">{errors.password.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="form-row button-row center">
                <Button
                  className="button"
                  type="submit"
                  disabled={!!Object.keys(errors).length || loading}>
                  Add user
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};
