import React, {FC} from 'react';
import {FeedbacksContext, FeedbacksContextI} from './FeedbacksContext';
import {GET_FEEDBACKS} from '../../graphQL/queries/feedbacks';
import {useLazyQuery} from '@apollo/client';
import {PaginationParams} from '../../http-client/types';
import {toast} from 'react-toastify';

const FeedbacksProvider: FC = ({children}) => {
  const [getFeedbacks, {data: feedbacks, loading, error}] = useLazyQuery(
    GET_FEEDBACKS,
    {
      onError: (error) => toast.error(error.message),
    },
  );

  const handleGetFeedbacks = async (
    payload: PaginationParams,
  ): Promise<void> => {
    await getFeedbacks({variables: payload});
  };

  const contextValues = (): FeedbacksContextI['Combined'] => {
    return {
      feedbacks,
      loading,
      error,
      onGetFeedbacks: handleGetFeedbacks,
    };
  };

  return (
    <FeedbacksContext.Provider value={contextValues()}>
      {children}
    </FeedbacksContext.Provider>
  );
};

export default FeedbacksProvider;
