import {gql} from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser($id: String!, $email: String!) {
    userUpdate(id: $id, data: {email: $email}) {
      id
      displayImage
    }
  }
`;
