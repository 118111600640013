export enum SatisfactionValues {
  'Negative' = 1,
  'Dissatisfied' = 2,
  'Neutral' = 3,
  'Positive' = 4,
  'Satisfied' = 5,
}

export const getSatisfactionByValue = (value: number) => {
  return Object.values(SatisfactionValues)[value - 1];
};
