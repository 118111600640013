import {Button, Fade, Modal, TextField} from '@material-ui/core';
import React, {FC, useContext, useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {MODALS, useModals} from '../../containers';
import {yupResolver} from '@hookform/resolvers/yup';
import DeleteIcon from '@material-ui/icons/Delete';
import {addColleagueValidation} from '../../utils/validation';
import {Colleague} from '../../interfaces/colleague';
import classnames from 'classnames';
import {useMutation} from '@apollo/client';
import {
  CREATE_COLLEAGUE,
  REMOVE_COLLEAGUE,
  UPDATE_COLLEAGUE,
} from 'graphQL/mutations/colleague';
import {toast} from 'react-toastify';
import {ColleaguesContext} from '../../containers/Colleagues';
import {UserContext} from 'containers/User';
import {DEFAULT_PAGINATION} from 'constants/general';
import {DropdownSelect} from 'shared/Select';

export type AddColleagueModalProps = {
  open: boolean;
  colleague?: Colleague;
  shouldSelectLead?: boolean;
};

export const AddColleagueModal: FC<AddColleagueModalProps> = ({
  open = false,
  colleague,
  shouldSelectLead,
}) => {
  const {register, errors, getValues, control, handleSubmit} = useForm({
    mode: 'onTouched',
    resolver: yupResolver(addColleagueValidation),
    defaultValues: colleague
      ? {...colleague, userId: colleague.user?.id}
      : undefined,
  });

  const {closeModal, openModal} = useModals();
  const {onGetColleagues} = useContext(ColleaguesContext);
  const {onGetUsers, users, loading: usersLoading, error} = useContext(
    UserContext,
  );

  useEffect(() => {
    onGetUsers(DEFAULT_PAGINATION);
  }, []);

  const userOptions = useMemo(() => {
    if (!users?.length) {
      return [];
    }

    const options = users.map(({id, firstName, lastName}) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    }));

    options.push({
      value: null,
      label: 'None',
    });

    return options;
  }, [users]);

  const [colleagueCreate, {loading}] = useMutation(CREATE_COLLEAGUE, {
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      closeModal(MODALS.AddColleague);

      openModal<MODALS.Success>(MODALS.Success, {
        message: 'Your colleague has been successfully added',
      });
      onGetColleagues();
    },
  });

  const [colleagueUpdate, {loading: updateLoading}] = useMutation(
    UPDATE_COLLEAGUE,
    {
      onError: (error) => toast.error(error.message),
      onCompleted: ({colleagueUpdate}) => {
        closeModal(MODALS.AddColleague);
        openModal<MODALS.Success>(MODALS.Success, {
          message: 'Your colleague has been successfully updated',
        });
        onGetColleagues();
      },
    },
  );

  const [colleagueRemove, {loading: removeLoading}] = useMutation(
    REMOVE_COLLEAGUE,
    {
      onError: (error) => toast.error(error.message),
      onCompleted: ({colleagueUpdate}) => {
        closeModal(MODALS.AddColleague);
        openModal<MODALS.Success>(MODALS.Success, {
          message: 'Your colleague has been successfully removed',
        });
        onGetColleagues();
      },
    },
  );

  const handleClose = (): void => closeModal(MODALS.AddColleague);

  // const handleSubmitColleague = async ({
  //   firstName,
  //   lastName,
  //   position,
  // }: Partial<CreateColleagueRequestData>): Promise<void> => {
  //   await colleagueCreate({
  //     variables: {
  //       firstName,
  //       lastName,
  //       position,
  //       userId: currentUser?.id,
  //       isActive: true,
  //     },
  //   });
  // };

  const onSubmit = async (): Promise<void> => {
    try {
      if (colleague?.id) {
        await colleagueUpdate({
          variables: {
            colleagueId: colleague.id,
            ...getValues(),
          },
        });
      } else {
        await colleagueCreate({
          variables: {
            ...getValues(),
            isActive: true,
          },
        });
      }
    } catch (e) {}
  };

  const handleRemove = async (): Promise<void> => {
    if (colleague?.id) {
      await colleagueRemove({
        variables: {
          colleagueId: colleague.id,
        },
      });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}>
      <Fade in={open}>
        <div className="modal-body">
          <form action="#" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header">
              <p>{colleague?.id ? 'Edit' : 'Add'} Colleague</p>
              <i onClick={handleClose} className="icon-close" />
            </div>
            <div className="modal-content">
              <div className="modal-form">
                <div className="form-row">
                  <p className="form-label sm">First Name</p>
                  <TextField
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    variant="outlined"
                    inputRef={register}
                    className={classnames({
                      'error-input': !!errors.firstName,
                    })}
                  />
                  {!!errors.firstName && (
                    <p className="subtext error">{errors.firstName.message}</p>
                  )}
                </div>
                <div className="form-row">
                  <p className="form-label sm">Last Name</p>
                  <TextField
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    variant="outlined"
                    inputRef={register}
                    className={classnames({
                      'error-input': !!errors.lastName,
                    })}
                  />
                  {!!errors.lastName && (
                    <p className="subtext error">{errors.lastName.message}</p>
                  )}
                </div>
                <div className="form-row">
                  <p className="form-label sm">Position</p>
                  <TextField
                    id="position"
                    name="position"
                    placeholder="Position"
                    variant="outlined"
                    inputRef={register}
                    className={classnames({
                      'error-input': !!errors.position,
                    })}
                  />
                  {!!errors.position && (
                    <p className="subtext error">{errors.position.message}</p>
                  )}
                </div>

                {!!shouldSelectLead && (
                  <div className="form-row">
                    <p className="form-label sm">Lead</p>
                    <DropdownSelect
                      defaultValue={colleague?.user?.id}
                      ref={register}
                      labelId="userId-label"
                      name="userId"
                      id="userId"
                      options={userOptions}
                      control={control}
                    />

                    {!!errors.userId && (
                      <p className="subtext error">{errors.userId.message}</p>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <div className="form-row button-row space-around">
                {colleague?.id && (
                  <Button
                    className="button button-damage"
                    type="button"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={handleRemove}
                    disabled={
                      !!Object.keys(errors).length ||
                      loading ||
                      updateLoading ||
                      removeLoading
                    }>
                    Remove
                  </Button>
                )}
                <Button
                  className="button"
                  type="submit"
                  disabled={
                    !!Object.keys(errors).length ||
                    loading ||
                    updateLoading ||
                    removeLoading
                  }>
                  {colleague?.id ? 'Update' : 'Add'} colleague
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};
