export enum Pathnames {
  login = '/login',
  home = '/',
  changePassword = '/change-password',

  feedbacksHistory = '/feedbacks/:colleagueId',

  adminFeedbacks = '/admin/feedbacks',
  adminUsers = '/admin/users',

  notFound = '*',
}
