import React, {FC} from 'react';
import {Fade, Modal} from '@material-ui/core';
import {MODALS, useModals} from '../../containers';
import {getSatisfactionByValue} from '../../constants/satisfaction';
import {parseStringToDate} from '../../utils/parseStringToDate';

export interface ViewHistoryProps {
  open: boolean;
  currentProjectScore: number;
  teamScore: number;
  currentProjectComment: string;
  teamScoreComment: string;
  generalComment: string;
  createdAt: string;
}

export const ViewHistory: FC<ViewHistoryProps> = ({
  open,
  currentProjectScore,
  teamScore,
  currentProjectComment,
  teamScoreComment,
  generalComment,
  createdAt,
}) => {
  const {closeModal} = useModals();

  const handleClose = (): void => closeModal(MODALS.ViewHistory);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}>
      <Fade in={open}>
        <div className="modal-body no-scroll">
          <div className="modal-header">
            <p>
              Feedback from&nbsp;
              <span className="user">{parseStringToDate(createdAt)}</span>
            </p>
            <i onClick={handleClose} className="icon-close" />
          </div>
          <div className="modal-content">
            <div className="modal-form">
              <div className="feedback-section">
                <div className="form-row feedback-row">
                  <p className="form-label sm">Project</p>
                  <p className="feedback-text">
                    {generalComment || 'No comment'}
                  </p>
                </div>
              </div>
              <div className="feedback-section">
                <div className="form-row feedback-row">
                  <p className="form-label sm">
                    How does your colleague feel about this project
                  </p>
                  <p className="feedback-text">
                    {getSatisfactionByValue(currentProjectScore)}
                  </p>
                </div>
                <div className="form-row feedback-row">
                  <p className="form-label sm">Comment about project</p>
                  <p className="feedback-text">
                    {currentProjectComment || 'No comment'}
                  </p>
                </div>
              </div>
              <div className="feedback-section">
                <div className="form-row feedback-row">
                  <p className="form-label sm">
                    How does your colleague feel about the team of this project
                  </p>
                  <p className="feedback-text">
                    {getSatisfactionByValue(teamScore)}
                  </p>
                </div>
                <div className="form-row feedback-row">
                  <p className="form-label sm">Comment about team</p>
                  <p className="feedback-text">
                    {teamScoreComment || 'No comment'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
