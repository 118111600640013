import {gql} from '@apollo/client';

export const GET_SELF = gql`
  query getSelf {
    me {
      id
      firstName
      role
    }
  }
`;
