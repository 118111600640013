import React from 'react';
import {MODALS} from '../../../containers';
import TableButton from '../../../shared/TableButton';
import ColleaguesTable from './ColleaguesTable';

const ColleaguesList = () => {
  return (
    <main id="main">
      <section className="table-section">
        <div className="container">
          <div className="table-header">
            <h5 className="table-heading">Table of co-worker</h5>
            <TableButton
              text="Add Colleague"
              small
              modal={MODALS.AddColleague}
            />
          </div>
          <ColleaguesTable />
        </div>
      </section>
    </main>
  );
};

export default ColleaguesList;
