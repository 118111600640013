import React, {FC, useEffect, useState} from 'react';
import {ColleaguesContext, ColleaguesContextI} from './ColleaguesContext';
import {
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
} from '@apollo/client';
import {
  GET_ALL_COLLEAGUES,
  GET_COLLEAGUES,
} from '../../graphQL/queries/colleagues';
import {PaginationParams} from '../../http-client/types';
import {Colleague} from '../../interfaces/colleague';
import {Feedback} from '../../interfaces/feedback';
import {
  DEFAULT_PAGINATED_DATA,
  DEFAULT_PAGINATION,
} from '../../constants/general';
import {Paginated} from 'interfaces/table';

const ColleaguesProvider: FC = ({children}) => {
  const [colleagues, setColleagues] = useState<Array<Colleague>>([]);
  const [getColleagues, {data, loading, error}] = useLazyQuery(GET_COLLEAGUES, {
    fetchPolicy: 'network-only',
  });
  const [
    getAllColleagues,
    {data: allData, loading: allLoading, error: allError},
  ] = useLazyQuery<{allColleagues: Paginated<Colleague>}>(GET_ALL_COLLEAGUES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.colleagues) {
      setColleagues(data?.colleagues);
    }
  }, [data]);

  const handleGetColleagues = async ({
    page,
    per_page,
  }: PaginationParams = DEFAULT_PAGINATION): Promise<void> => {
    await getColleagues({variables: {page, per_page}});
  };

  const handleGetAllColleagues = async (
    options?: QueryLazyOptions<OperationVariables>,
  ): Promise<void> => {
    await getAllColleagues(options);
  };

  // const handleUpdateLastFeedbackDate = (
  //   colleagueId: string,
  //   date: string,
  // ): void => {
  //   const c = [...colleagues];
  //   for (let i = 0; i < c.length; i++) {
  //     if (c[i].id === colleagueId) {
  //       c[i] = {...c[i], lastFeedbackDate: date};
  //       break;
  //     }
  //   }
  //   setColleagues(c);
  // };

  const handleUpdateColleagueFeedback = (
    colleagueId: string,
    feedback: Feedback,
  ): void => {
    // const c = [...colleagues];
    // let currentColleague: Colleague | undefined;
    // let currentColleagueIndex: number | undefined;
    // for (let i = 0; i < c.length; i++) {
    //   if (c[i].id === colleagueId) {
    //     currentColleague = c[i];
    //     currentColleagueIndex = i;
    //     break;
    //   }
    // }
    //
    // if (currentColleague === undefined || currentColleagueIndex === undefined) {
    //   return;
    // }
    //
    // if (currentColleague.feedbacks.length > 0) {
    //   const feedbacks = [...currentColleague.feedbacks];
    //   let exist = false;
    //   for (let i = 0; i < feedbacks.length; i++) {
    //     if (feedbacks[i].id === feedback.id) {
    //       feedbacks[i] = {...feedbacks[i], ...feedback};
    //       exist = true;
    //       break;
    //     }
    //   }
    //   if (!exist) {
    //     feedbacks.push(feedback);
    //   }
    //
    //   c[currentColleagueIndex] = {
    //     ...c[currentColleagueIndex],
    //     feedbacks: [...feedbacks],
    //   };
    // } else {
    //   c[currentColleagueIndex] = {
    //     ...c[currentColleagueIndex],
    //     feedbacks: [feedback],
    //   };
    // }
    //
    // setColleagues([...c]);
  };

  const contextValues = (): ColleaguesContextI['Combined'] => {
    return {
      colleagues,
      loading: loading || allLoading,
      error: error || allError,
      allColleagues: allData?.allColleagues ?? DEFAULT_PAGINATED_DATA,
      onGetColleagues: handleGetColleagues,
      onGetAllColleagues: handleGetAllColleagues,
      // updateLastFeedbackDate: handleUpdateLastFeedbackDate,
      // updateColleagueFeedback: handleUpdateColleagueFeedback,
    };
  };

  return (
    <ColleaguesContext.Provider value={contextValues()}>
      {children}
    </ColleaguesContext.Provider>
  );
};

export default ColleaguesProvider;
