import React, {FC, useContext, useEffect} from 'react';
import {useHistory, Route} from 'react-router-dom';
import {Pathnames} from './routes';
import {ProtectedRouteType} from './types';
import {Roles} from '../interfaces';
import {AuthContext} from '../containers';

type ProtectedRouteProps = ProtectedRouteType;

export const RouteResolver: FC<ProtectedRouteProps> = ({
  Component,
  guard,
  roles,
  ...rest
}) => {
  const {authorized, currentUser} = useContext(AuthContext);

  const addAuthorize = (
    Component: React.ComponentType,
  ): React.ComponentType => {
    const Redirect = () => {
      const history = useHistory();
      useEffect(() => {
        history.push(authorized ? Pathnames.home : Pathnames.login);
      }, [history]);

      return null;
    };

    if (guard) {
      if (authorized) {
        if (currentUser && roles) {
          if (roles.includes(currentUser?.role)) {
            return Component;
          }
          return Redirect;
        }
        return Component;
      } else {
        return Redirect;
      }
    }
    return Component;
  };

  const EnhancedComponent = addAuthorize(Component);

  return (
    <Route {...rest}>
      <EnhancedComponent />
    </Route>
  );
};
