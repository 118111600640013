import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {ColleaguesContext} from '../../../../containers/Colleagues';
import {PaginationParams} from '../../../../http-client/types';
import {DEFAULT_PAGINATION} from '../../../../constants/general';
import {MODALS} from '../../../../containers';
import {CircularProgress} from '@material-ui/core';
import TableButton from '../../../../shared/TableButton';
import {Link} from 'react-router-dom';
import {Colleague} from '../../../../interfaces/colleague';
import {Feedback, FeedbackStatus} from 'interfaces/feedback';
import EditColleagueButton from './EditColleagueButton';

const parseStringToDate = (stringToParse: string): string => {
  return new Date(stringToParse).toLocaleString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

const ColleaguesTable: FC = () => {
  const {onGetColleagues, colleagues, loading} = useContext(ColleaguesContext);
  const [pagination, setPagination] = useState<PaginationParams>(
    DEFAULT_PAGINATION,
  );

  useEffect((): void => {
    onGetColleagues(pagination);
  }, []);

  const draftFeedback = (colleague: Colleague): Feedback | null => {
    const draft = colleague?.feedbacks?.filter(
      (f) => f.status === FeedbackStatus.Draft,
    );

    return !!draft && draft?.length > 0 ? draft[0] : null;
  };

  const isDraft = (colleague: Colleague): boolean => {
    // colleague.feedbacks.filter((f) => f.status === FeedbackStatus.Draft);
    return !!colleague?.feedbacks && colleague.feedbacks.length > 0
      ? colleague.feedbacks.filter((f) => f.status === FeedbackStatus.Draft)
          .length > 0
      : false;
  };

  const lastFeedbackDate = useCallback((colleague: Colleague): string => {
    if (!colleague?.feedbacks?.length) {
      return 'No feedback';
    }

    const sorted = [...colleague.feedbacks].sort((a, b) => {
      // @ts-ignore
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    return parseStringToDate(sorted[0].createdAt);
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Name</b>
            </TableCell>
            <TableCell align="center">
              <b>Position</b>
            </TableCell>
            <TableCell align="center">
              <b>Last feedback date</b>
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && <CircularProgress />}
          {!!colleagues?.length &&
            colleagues.map((colleague) => (
              <TableRow key={colleague.id}>
                <TableCell className="user-name-col" component="th" scope="row">
                  {colleague.firstName} {colleague.lastName}
                </TableCell>
                <TableCell align="center">{colleague.position}</TableCell>
                <TableCell align="center">
                  {lastFeedbackDate(colleague)}
                </TableCell>
                <TableCell align="right">
                  <Link to={`/feedbacks/${colleague.id}`}>View History</Link>
                </TableCell>
                <TableCell align="right">
                  <EditColleagueButton colleague={colleague} />
                  {isDraft(colleague) ? (
                    <TableButton
                      text="View Draft"
                      modal={MODALS.AddFeedback}
                      modalOptions={{
                        colleagueId: colleague.id,
                        colleagueName: `${colleague.firstName} ${colleague.lastName}`,
                        draft: draftFeedback(colleague),
                      }}
                    />
                  ) : (
                    <TableButton
                      text="Add Feedback"
                      modal={MODALS.AddFeedback}
                      modalOptions={{
                        colleagueId: colleague.id,
                        colleagueName: `${colleague.firstName} ${colleague.lastName}`,
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ColleaguesTable;
