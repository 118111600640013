export interface Feedback {
  createdAt: string;
  updatedAt: string;
  id: string;
  currentProjectScore: number;
  currentProjectComment: string;
  teamScore: number;
  teamScoreComment: string;
  improveComment: string;
  generalComment: string;
  status: FeedbackStatus;
}

export interface CreateFeedbackRequestData {
  currentProjectScore: number;
  currentProjectComment?: string;
  teamScore: number;
  teamScoreComment?: string;
  improveComment?: string;
  generalComment?: string;
  colleagueId: string;
}

export enum FeedbackStatus {
  Draft = 'Draft',
  Published = 'Published',
}
