import {gql} from '@apollo/client';

export const GET_FEEDBACKS = gql`
  query getFeedbacks($page: Int!, $per_page: Int!) {
    allFeedbacks(page: $page, per_page: $per_page) {
      createdAt
      updatedAt
      id
      currentProjectScore
      currentProjectComment
      teamScore
      teamScoreComment
      improveComment
      generalComment
      colleague {
        id
        firstName
        lastName
        position
      }
    }
  }
`;

export const GET_FEEDBACKS_HISTORY = gql`
  query feedbacksHistory($colleagueId: ID!) {
    feedbacksHistory(colleagueId: $colleagueId) {
      createdAt
      updatedAt
      id
      currentProjectScore
      currentProjectComment
      teamScore
      teamScoreComment
      improveComment
      generalComment
    }
  }
`;
