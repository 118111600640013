import React, {useState} from 'react';
import classnames from 'classnames';
import BaseDrawer from '@material-ui/core/Drawer';
import Navigation from '../Navigation';

const Drawer = () => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className={classnames(['burger-btn', {active: open}])}
        onClick={handleDrawerOpen}>
        <span />
        <span />
        <span />
      </div>

      <BaseDrawer anchor="left" open={open}>
        <div
          className={classnames(['burger-btn', {active: open}])}
          onClick={handleDrawerClose}>
          <span />
          <span />
          <span />
        </div>
        <Navigation isSidebarMenu />
      </BaseDrawer>
    </>
  );
};

export default Drawer;
