import React, {FC, useContext} from 'react';
import {Button, TextField} from '@material-ui/core';
import {Header} from 'shared/Header/Header';
import {AuthContext} from '../../containers';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {loginValidation} from '../../utils/validation';
import useKeyPress from 'hooks/useKeyPress';
import {LoginRequestData} from '../../interfaces';

const Login: FC = (): JSX.Element => {
  const {onLogin, error, loading} = useContext(AuthContext);
  const {register, errors, watch, trigger} = useForm({
    mode: 'onBlur',
    resolver: yupResolver(loginValidation),
  });

  const values = watch();

  useKeyPress(
    'Enter',
    () => {
      onLogin(values as LoginRequestData);
    },
    [values],
  );

  const errorText = error?.graphQLErrors.find((err) => {
    const {path} = err;
    if (path && path[0] == 'login') {
      return err;
    }
  });

  const handleLogin = async (): Promise<void> => {
    try {
      const validated = await trigger();

      if (validated) {
        onLogin(values as LoginRequestData);
      }
    } catch (e) {}
  };

  return (
    <div id="wrapper">
      <Header onlyLogo />
      <div className="full-height-holder with-header">
        <div className="full-height-inner">
          <div className="login-holder">
            <h5 className="login-heading">Login to account in DDI system</h5>
            <form className="login-form">
              <div className="form-row">
                <p className="form-label">Email</p>
                <TextField
                  variant="outlined"
                  id="email"
                  name="email"
                  inputRef={register}
                  placeholder="Your email"
                  error={!!error || !!errors.email}
                />
                {errors.email && (
                  <p className="subtext error">
                    {errors.email.message || errorText?.message}
                  </p>
                )}
              </div>
              <div className="form-row">
                <p className="form-label">Password</p>
                <TextField
                  id="password"
                  type="password"
                  name="password"
                  variant="outlined"
                  autoComplete="current-password"
                  placeholder="Enter your password"
                  error={!!error || !!errors.password}
                  inputRef={register}
                />
                {errors.password && (
                  <p className="subtext error">
                    {errors.password.message || errorText?.message}
                  </p>
                )}
              </div>
              <div className="form-row" onClick={handleLogin}>
                <Button
                  className="button"
                  fullWidth
                  disabled={!!Object.keys(errors).length || loading}>
                  Login
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
