import {DependencyList, useCallback, useEffect} from 'react';

const useKeyPress = (
  targetKey: string,
  callback: () => void,
  deps?: DependencyList,
): void => {
  const onDown = useCallback(
    ({key}: KeyboardEvent) => {
      if (key === targetKey) {
        callback();
      }
    },
    [callback, targetKey],
  );

  useEffect(() => {
    window.addEventListener('keydown', onDown);

    return () => {
      window.removeEventListener('keydown', onDown);
    };
  }, [deps, onDown]);
};

export default useKeyPress;
