import * as yup from 'yup';

export const loginValidation = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .email('Must be a valid email address')
    .required('Please add an email address'),
  password: yup.string().nullable().required('Please enter password'),
});

export const addFeedbackValidation = yup.object().shape({
  currentProjectScore: yup
    .number()
    .nullable()
    // .typeError('Please, write a number')
    .required('Please, set a project score'),
  teamScore: yup
    .number()
    .nullable()
    // .typeError('Please, write a number')
    .required('Please, set a team score'),
});

export const addColleagueValidation = yup.object().shape({
  firstName: yup.string().nullable().required('Please enter the first name'),
  lastName: yup.string().nullable().required('Please enter the last name'),
  position: yup.string().nullable().required('Please enter the position'),
  userId: yup.string().nullable().notRequired(),
});

export const addUserValidation = yup.object().shape({
  firstName: yup.string().nullable().required('Please enter the first name'),
  lastName: yup.string().nullable().required('Please enter the last name'),
  email: yup.string().nullable().required('Please enter the email'),
  password: yup.string().nullable().required('Please enter the password'),
});

export const changePasswordValidation = yup.object().shape({
  currentPassword: yup
    .string()
    .nullable()
    .required('Current password is required'),
  newPassword: yup
    .string()
    .min(8, 'Too Short!')
    .notOneOf(
      [yup.ref('currentPassword')],
      'The password must not be the same as the current one',
    )
    .required('New password is required'),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('newPassword')],
      'Passwords must match with the new password',
    )
    .required('Confirm password is required'),
});
