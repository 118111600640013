import {gql} from '@apollo/client';

export const GET_USERS = gql`
  query getUsers($page: Int!, $per_page: Int!) {
    users(page: $page, per_page: $per_page) {
      createdAt
      updatedAt
      id
      email
      firstName
      lastName
      role
    }
  }
`;
