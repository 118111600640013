import React, {FC, useCallback, useContext} from 'react';
import {Colleague} from '../../../../interfaces/colleague';
import {MODALS, ModalsContext} from '../../../../containers';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import IconButton from '@material-ui/core/IconButton';

type EditColleagueButtonProps = {
  colleague: Colleague;
};

const EditColleagueButton: FC<EditColleagueButtonProps> = ({colleague}) => {
  const {openModal} = useContext(ModalsContext);

  const handleEdit = useCallback((): void => {
    openModal<MODALS.AddColleague>(MODALS.AddColleague, {colleague});
  }, [colleague, openModal]);

  return (
    <IconButton color="primary" onClick={handleEdit}>
      <CreateOutlinedIcon />
    </IconButton>
  );
};

export default EditColleagueButton;
