import React, {memo} from 'react';
import Colleagues from './Colleagues';
import {Header} from 'shared/Header/Header';

const HomePage = (): JSX.Element => (
  <div id="wrapper">
    <Header />
    <Colleagues />
  </div>
);

export default memo(HomePage);
