import {createContext} from 'react';
import {
  ApolloError,
  OperationVariables,
  QueryLazyOptions,
} from '@apollo/client';
import {PaginationParams} from '../../http-client/types';
import {
  Colleague,
  CreateColleagueRequestData,
} from '../../interfaces/colleague';
import {DEFAULT_PAGINATED_DATA} from '../../constants/general';
import {Paginated} from 'interfaces/table';

export interface ColleaguesContextI {
  readonly State: {
    loading: boolean;
    error?: ApolloError;
    colleagues: Array<Colleague>;
    allColleagues: Paginated<Colleague>;
  };
  readonly Actions: {
    onGetColleagues: (payload?: PaginationParams) => Promise<any> | undefined;
    onGetAllColleagues: (
      options?: QueryLazyOptions<OperationVariables>,
    ) => Promise<any> | undefined;
    // updateLastFeedbackDate: (colleagueId: string, date: string) => void;
    // updateColleagueFeedback: (colleagueId: string, feedback: Feedback) => void;
  };
  readonly Combined: ColleaguesContextI['State'] &
    ColleaguesContextI['Actions'];
}
export const ColleaguesContext = createContext<ColleaguesContextI['Combined']>({
  error: undefined,
  loading: false,
  colleagues: [],
  allColleagues: DEFAULT_PAGINATED_DATA,

  onGetColleagues: () => undefined,
  onGetAllColleagues: () => undefined,
  // updateLastFeedbackDate: () => undefined,
  // updateColleagueFeedback: () => undefined,
});
