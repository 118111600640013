import React, {FC} from 'react';
import {Link} from '@material-ui/core';
import classnames from 'classnames';
import {useLocation} from 'react-router-dom';
import {Pathnames} from '../../../routing/routes';

type NavigationProps = {
  isSidebarMenu?: boolean;
};

const Pages = [
  {
    id: 'feedbacks',
    title: 'Feedbacks',
    path: Pathnames.adminFeedbacks,
  },
  {
    id: 'users',
    title: 'Users',
    path: Pathnames.adminUsers,
  },
];

const Navigation: FC<NavigationProps> = ({isSidebarMenu}) => {
  const location = useLocation();

  return (
    <ul className={classnames({'sidebar-list': isSidebarMenu})}>
      {Pages.map((page) => {
        return (
          <li key={page.id}>
            <Link
              href={page.path}
              className={classnames({
                active: location.pathname === page.path,
              })}>
              {page.title}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default Navigation;
