import {
  Button,
  Fade,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@material-ui/core';
import React, {FC, useContext, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {MODALS, useModals} from '../../containers';
import {
  CreateFeedbackRequestData,
  Feedback,
  FeedbackStatus,
} from '../../interfaces/feedback';
import {yupResolver} from '@hookform/resolvers/yup';
import {addFeedbackValidation} from '../../utils/validation';
import {useMutation} from '@apollo/client';
import {
  CREATE_FEEDBACK,
  UPDATE_FEEDBACK,
} from '../../graphQL/mutations/feedback';
import {toast} from 'react-toastify';
import {ColleaguesContext} from '../../containers/Colleagues';
import {DropdownSelect} from '../../shared/Select';
import {DEFAULT_PAGINATION} from '../../constants/general';

const satisfactionOptions = [
  {value: 1, label: 'Negative'},
  {value: 2, label: 'Dissatisfied'},
  {value: 3, label: 'Neutral'},
  {value: 4, label: 'Positive'},
  {value: 5, label: 'Satisfied'},
];

export interface AddFeedbackModalProps {
  colleagueId: string;
  colleagueName: string;
  open: boolean;
  draft?: Feedback;
}

const modalMessage = (status: FeedbackStatus): string => {
  return status === FeedbackStatus.Published
    ? 'Your feedback has been successfully added'
    : 'Your draft has been successfully saved';
};

export const AddFeedbackModal: FC<AddFeedbackModalProps> = ({
  colleagueId,
  colleagueName,
  open,
  draft,
}) => {
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    watch,
    control,
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(addFeedbackValidation),
    defaultValues: draft ? {...draft} : undefined,
  });

  const values = watch();

  const {onGetColleagues} = useContext(ColleaguesContext);
  const {closeModal, openModal} = useModals();
  const [createFeedback, {loading}] = useMutation(CREATE_FEEDBACK, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: ({feedbackCreate}) => {
      closeModal(MODALS.AddFeedback);
      openModal<MODALS.Success>(MODALS.Success, {
        message: modalMessage(feedbackCreate.status),
      });
      onGetColleagues();
    },
  });

  const [updateFeedback, {loading: publishLoading}] = useMutation(
    UPDATE_FEEDBACK,
    {
      onError: (error) => toast.error(error.message),
      onCompleted: ({feedbackUpdate}) => {
        closeModal(MODALS.AddFeedback);
        openModal<MODALS.Success>(MODALS.Success, {
          message: modalMessage(feedbackUpdate.status),
        });
        onGetColleagues();
      },
    },
  );

  useEffect((): void => {
    register('teamScore');
    register('currentProjectScore');
  }, [register]);

  useEffect(() => {
    if (draft?.id) {
      setValue('currentProjectScore', draft.currentProjectScore);
      setValue('teamScore', draft.teamScore);
      trigger('currentProjectScore');
      trigger('teamScore');
    }
  }, [draft, setValue, trigger]);

  const handleClose = (): void => closeModal(MODALS.AddFeedback);

  const handleSaveDraft = async (): Promise<void> => {
    try {
      const validated = await trigger();

      if (validated) {
        if (draft?.id) {
          updateFeedback({
            variables: {
              ...getValues(),
              feedbackId: draft.id,
              colleagueId,
              status: FeedbackStatus.Draft,
            },
          });
        } else {
          createFeedback({
            variables: {
              ...getValues(),
              colleagueId,
              status: FeedbackStatus.Draft,
            },
          });
        }
      }
    } catch (e) {}
  };

  const handleSubmitFeedback = async (): Promise<void> => {
    try {
      const validated = await trigger();

      if (validated) {
        if (draft?.id) {
          updateFeedback({
            variables: {
              ...getValues(),
              feedbackId: draft.id,
              colleagueId,
              status: FeedbackStatus.Published,
            },
          });
        } else {
          createFeedback({
            variables: {
              ...getValues(),
              colleagueId,
              status: FeedbackStatus.Published,
            },
          });
        }
      }
    } catch (e) {}
  };

  // const handleChangeRating = (fieldName: string) => (event: any): void => {
  //   setValue(fieldName, event.target.value);
  //   trigger(fieldName);
  // };

  const handleChangeDropdown = (
    fieldName: 'currentProjectScore' | 'teamScore',
  ) => (value: any): void => {
    setValue(fieldName, value, {shouldValidate: true, shouldDirty: true});
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}>
      <Fade in={open}>
        <div className="modal-body">
          <form>
            <div className="modal-header">
              <p>
                Feedback for <span className="user">{colleagueName}</span>
              </p>
              <i onClick={handleClose} className="icon-close" />
            </div>
            <div className="modal-content">
              <div className="modal-form">
                <div className="form-row">
                  <p className="form-label sm">
                    How does your colleague feel about this project?
                  </p>
                  <DropdownSelect
                    ref={register}
                    labelId="currentProjectScore-label"
                    name="currentProjectScore"
                    id="currentProjectScore"
                    defaultValue={draft?.currentProjectScore}
                    value={values.currentProjectScore}
                    // onChange={handleChangeDropdown('currentProjectScore')}
                    options={satisfactionOptions}
                    control={control}
                  />
                  {/*<HalfRating*/}
                  {/*  id="currentProjectScore"*/}
                  {/*  name="currentProjectScore"*/}
                  {/*  onChangeRating={handleChangeRating('currentProjectScore')}*/}
                  {/*/>*/}
                  {!!errors.currentProjectScore && (
                    <p className="subtext error">
                      {errors.currentProjectScore.message}
                    </p>
                  )}
                </div>
                <div className="form-row">
                  <p className="form-label sm">Project comment</p>
                  <TextField
                    className="resizable-textarea"
                    id="currentProjectComment"
                    name="currentProjectComment"
                    multiline
                    rows={4}
                    placeholder="eg ideas, comments, wishes"
                    variant="outlined"
                    inputRef={register}
                  />
                </div>
                <div className="form-row">
                  <p className="form-label sm">
                    How does your colleague feel about the team on the project?
                  </p>
                  <DropdownSelect
                    defaultValue={draft?.teamScore}
                    ref={register}
                    labelId="teamScore-label"
                    name="teamScore"
                    id="teamScore"
                    value={values.teamScore}
                    // onChange={handleChangeDropdown('teamScore')}
                    options={satisfactionOptions}
                    control={control}
                  />
                  {/*<HalfRating*/}
                  {/*  id="teamScore"*/}
                  {/*  name="teamScore"*/}
                  {/*  onChangeRating={handleChangeRating('teamScore')}*/}
                  {/*/>*/}
                  {!!errors.teamScore && (
                    <p className="subtext error">{errors.teamScore.message}</p>
                  )}
                </div>
                <div className="form-row">
                  <p className="form-label sm">Team comment</p>
                  <TextField
                    className="resizable-textarea"
                    id="teamScoreComment"
                    name="teamScoreComment"
                    multiline
                    rows={4}
                    placeholder="eg ideas, comments, wishes"
                    variant="outlined"
                    inputRef={register}
                  />
                </div>
                {/*<div className="form-row">*/}
                {/*  <p className="form-label sm">Comment for improvements</p>*/}
                {/*  <TextField*/}
                {/*    className="resizable-textarea"*/}
                {/*    id="improveComment"*/}
                {/*    name="improveComment"*/}
                {/*    multiline*/}
                {/*    rows={4}*/}
                {/*    placeholder="Your comment"*/}
                {/*    variant="outlined"*/}
                {/*    inputRef={register}*/}
                {/*  />*/}
                {/*</div>*/}
                <div className="form-row">
                  <p className="form-label sm">General comment</p>
                  <TextField
                    className="resizable-textarea"
                    id="generalComment"
                    name="generalComment"
                    multiline
                    rows={4}
                    placeholder="eg general comments, thoughts about the company"
                    variant="outlined"
                    inputRef={register}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="form-row button-row space-around">
                <Button
                  onClick={handleSaveDraft}
                  className="button button-light"
                  type="button"
                  disabled={
                    !!Object.keys(errors).length || loading || publishLoading
                  }>
                  Save Draft
                </Button>
                <Button
                  onClick={handleSubmitFeedback}
                  className="button"
                  type="button"
                  disabled={
                    !!Object.keys(errors).length || loading || publishLoading
                  }>
                  Add feedback
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};
