import {createContext} from 'react';
import {ApolloError} from '@apollo/client';
import {LoginRequestData, User} from '../../interfaces';

export interface AuthContextI {
  readonly State: {
    currentUser: User | undefined;
    authorized: boolean;
    loading: boolean;
    error?: ApolloError;
  };

  readonly Actions: {
    onLogin: (payload: LoginRequestData) => Promise<void> | undefined;
    onLogout: () => Promise<void> | undefined;
  };
  readonly Combined: AuthContextI['State'] & AuthContextI['Actions'];
}

export const AuthContext = createContext<AuthContextI['Combined']>({
  onLogin: () => undefined,
  onLogout: () => undefined,
  currentUser: undefined,
  authorized: false,
  error: undefined,
  loading: false,
});
