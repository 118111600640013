import React, {FC, useEffect} from 'react';
import {MenuItem, Select} from '@material-ui/core';
import {SelectProps} from '@material-ui/core/Select/Select';
import {Controller} from 'react-hook-form';
import {SelectOption} from 'interfaces/common';

type DropdownSelectProps = SelectProps & {
  options: Array<SelectOption>;
  control: any;
  name: string;
};

export const DropdownSelect: FC<DropdownSelectProps> = (props) => {
  const {name, options, control} = props;

  return (
    <Controller
      as={
        <Select
          {...props}
          variant="outlined"
          // onChange={handleChangeRating}
          className="dropdown fullwidth">
          {options.map(({label, value}) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      }
      name={name}
      control={control}
    />
  );
};
