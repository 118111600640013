import React, {FC, useContext} from 'react';
import {Button} from '@material-ui/core';
import {MODALS, ModalsContext, MODALS_PROPS} from 'containers';
import classnames from 'classnames';

type TableButtonOptions = {
  text: string;
  modal: MODALS;
  modalOptions?: MODALS_PROPS[MODALS];
  small?: boolean;
  linkView?: boolean;
};

const TableButton: FC<TableButtonOptions> = ({
  text,
  modal,
  modalOptions,
  small,
  linkView,
}) => {
  const {openModal} = useContext(ModalsContext);

  const handleOpen = () => {
    openModal(modal, modalOptions);
  };

  return (
    <>
      {linkView ? (
        <span className="table-button link-view" onClick={handleOpen}>
          {text}
        </span>
      ) : (
        <Button
          className={classnames(['button table-button', {small: small}])}
          onClick={handleOpen}>
          {text}
        </Button>
      )}
    </>
  );
};

export default TableButton;
