import React, {Suspense} from 'react';
import {MuiThemeProvider} from '@material-ui/core';
import {BrowserRouter, Switch} from 'react-router-dom';
import {LocalizationProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {ModalsProvider, AVAILABLE_MODALS} from 'containers/Modal';
import {RouteResolver, Routes} from 'routing';
import theme from './theme';
import {ApolloProvider} from '@apollo/client';
import client from './apolloClient';
import AuthProvider from './containers/Auth/AuthProvider';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FeedbacksProvider from './containers/Feedbacks/FeedbacksProvider';
import ColleaguesProvider from './containers/Colleagues/ColleaguesProvider';
import UserProvider from 'containers/User/UserProvider';

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <ApolloProvider client={client}>
            <AuthProvider>
              <FeedbacksProvider>
                <UserProvider>
                  <ColleaguesProvider>
                    <ModalsProvider initialModals={AVAILABLE_MODALS}>
                      <Suspense fallback={<>Loading...</>}>
                        <Switch>
                          {Routes.map(
                            ({Component, guard, roles, path, ...rest}) => (
                              <RouteResolver
                                key={path || '#'}
                                guard={guard}
                                roles={roles}
                                Component={Component}
                                path={path}
                                {...rest}
                              />
                            ),
                          )}
                        </Switch>
                      </Suspense>
                    </ModalsProvider>
                  </ColleaguesProvider>
                </UserProvider>
              </FeedbacksProvider>
            </AuthProvider>
          </ApolloProvider>
        </LocalizationProvider>

        <ToastContainer />
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
