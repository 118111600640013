import {STORAGE_PREFIX} from './configuration';
import {AuthPayloadI} from '../interfaces';

export function saveToken(token: AuthPayloadI): void {
  localStorage.setItem(`${STORAGE_PREFIX}:keys`, JSON.stringify(token));
}

export function getToken(): AuthPayloadI | null {
  const keys = localStorage.getItem(`${STORAGE_PREFIX}:keys`);
  return keys ? JSON.parse(keys) : null;
}

export function removeToken(): void {
  localStorage.removeItem(`${STORAGE_PREFIX}:keys`);
}
