import React, {FC, memo} from 'react';
import notFound from 'images/not-found-img.svg';

interface NotFoundPropsI {
  children: React.ReactNode;
  classes?: {[key: string]: any};
  route?: string;
}

const NotFound: FC<NotFoundPropsI> = ({
  route,
  classes,
  children,
}): JSX.Element => (
  <div id="wrapper">
    <main id="main" className="without-header">
      <div className="full-height-holder">
        <div className="full-height-inner">
          <div className="not-found-container">
            <img src={notFound} alt="not found" />
            <div className="not-found-description">
              <h4>Ooops! That page is gone.</h4>
              <h1 className="error-code">404</h1>
              <p>Try another time</p>
              <a href="/" className="button">
                Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
);

export default memo(NotFound);
