import React, {FC, useContext, useEffect, useState} from 'react';
import {AppBar, Avatar, Button, Link, Toolbar} from '@material-ui/core';
import classNames from 'classnames';
import logo from 'images/logo.svg';
import TemporaryDrawer from './Drawer';
import classnames from 'classnames';
import {AuthContext} from '../../containers';
import Navigation from './Navigation';
import avatar from 'images/user.png';

type HeaderProps = {
  isMenu?: boolean;
  onlyLogo?: boolean;
  isAdmin?: boolean;
};

export const Header: FC<HeaderProps> = ({isMenu, onlyLogo, isAdmin}) => {
  const {onLogout, currentUser} = useContext(AuthContext);
  const [scrolled, setScrolled] = useState<boolean>(
    window.pageYOffset > 0 || window.scrollY > 1,
  );

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = (): void => {
    if (window.scrollY > 1 && !scrolled) {
      setScrolled(true);
    } else if (window.scrollY < 1) {
      setScrolled(false);
    }
  };

  return (
    <AppBar position="fixed" className={classNames({'is-fixed': scrolled})}>
      <Toolbar className="header-wrapper">
        <div className="navigation">
          <Link href="/" className="logo">
            <img src={logo} alt="GeniusWire" />
          </Link>
        </div>
        {!onlyLogo && (
          <>
            {isMenu && (
              <div className="menu-navigation">
                <Navigation />
                <TemporaryDrawer />
              </div>
            )}
            <div
              className={classnames([
                'button-holder',
                {'menu-variant': isMenu},
              ])}>
              {isAdmin && (
                <div className="admin-label">
                  <span>Admin area</span>
                </div>
              )}
              <div className="user">
                <Link href="/change-password">
                  <Avatar alt="avatar" src={avatar} />
                </Link>
                <div className="user-hi-text">
                  <span className="name">Hi, {currentUser?.firstName}</span>
                  <span className="role">{currentUser?.role}</span>
                </div>
              </div>
              <Button className="button" onClick={onLogout}>
                Logout
              </Button>
            </div>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};
