import {gql} from '@apollo/client';

export const CREATE_COLLEAGUE = gql`
  mutation createColleague(
    $firstName: String!
    $lastName: String!
    $position: String!
    $isActive: Boolean
    $userId: String
  ) {
    colleagueCreate(
      data: {
        firstName: $firstName
        lastName: $lastName
        position: $position
        isActive: $isActive
        userId: $userId
      }
    ) {
      createdAt
      updatedAt
      id
      firstName
      lastName
      position
    }
  }
`;

export const UPDATE_COLLEAGUE = gql`
  mutation colleagueUpdate(
    $colleagueId: ID!
    $firstName: String!
    $lastName: String!
    $position: String!
    $isActive: Boolean
    $userId: String
  ) {
    colleagueUpdate(
      colleagueId: $colleagueId
      data: {
        firstName: $firstName
        lastName: $lastName
        position: $position
        isActive: $isActive
        userId: $userId
      }
    ) {
      createdAt
      updatedAt
      id
      firstName
      lastName
      position
      isActive
    }
  }
`;

export const REMOVE_COLLEAGUE = gql`
  mutation colleagueRemove($colleagueId: ID!) {
    colleagueRemove(colleagueId: $colleagueId) {
      id
    }
  }
`;
