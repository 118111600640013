import {gql} from '@apollo/client';

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token_type
      jwt_token
      expires_in
      refresh_token
      refresh_token_expires_at
    }
  }
`;
