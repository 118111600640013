import {gql} from '@apollo/client';

export const CREATE_FEEDBACK = gql`
  mutation createFeedback(
    $currentProjectScore: Float!
    $currentProjectComment: String
    $teamScore: Float!
    $teamScoreComment: String
    $improveComment: String
    $generalComment: String
    $colleagueId: String!
    $status: FeedbackStatusEnum!
  ) {
    feedbackCreate(
      data: {
        currentProjectScore: $currentProjectScore
        currentProjectComment: $currentProjectComment
        teamScore: $teamScore
        teamScoreComment: $teamScoreComment
        improveComment: $improveComment
        generalComment: $generalComment
        colleagueId: $colleagueId
        status: $status
      }
    ) {
      createdAt
      updatedAt
      id
      currentProjectScore
      currentProjectComment
      teamScore
      teamScoreComment
      improveComment
      generalComment
      status
    }
  }
`;

export const UPDATE_FEEDBACK = gql`
  mutation feedbackUpdate(
    $feedbackId: ID!
    $currentProjectScore: Float!
    $currentProjectComment: String
    $teamScore: Float!
    $teamScoreComment: String
    $improveComment: String
    $generalComment: String
    $colleagueId: String!
    $status: FeedbackStatusEnum!
  ) {
    feedbackUpdate(
      feedbackId: $feedbackId
      data: {
        currentProjectScore: $currentProjectScore
        currentProjectComment: $currentProjectComment
        teamScore: $teamScore
        teamScoreComment: $teamScoreComment
        improveComment: $improveComment
        generalComment: $generalComment
        colleagueId: $colleagueId
        status: $status
      }
    ) {
      createdAt
      updatedAt
      id
      currentProjectScore
      currentProjectComment
      teamScore
      teamScoreComment
      improveComment
      generalComment
      status
    }
  }
`;
