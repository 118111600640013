export const DEFAULT_PAGINATION = {
  page: 0,
  per_page: 50,
};

export const DEFAULT_PAGINATED_DATA = {
  data: [],
  pageInfo: {
    total: 0,
    currentPage: 0,
  },
};
