import {createContext} from 'react';
import {ApolloError} from '@apollo/client';
import {PaginationParams} from '../../http-client/types';
import {CreateFeedbackRequestData, Feedback} from '../../interfaces/feedback';

export interface FeedbacksContextI {
  readonly State: {
    loading: boolean;
    error?: ApolloError;
    feedbacks: Array<Feedback>;
  };
  readonly Actions: {
    onGetFeedbacks: (payload: PaginationParams) => Promise<any> | undefined;
  };
  readonly Combined: FeedbacksContextI['State'] & FeedbacksContextI['Actions'];
}
export const FeedbacksContext = createContext<FeedbacksContextI['Combined']>({
  error: undefined,
  loading: false,
  feedbacks: [],
  onGetFeedbacks: () => undefined,
});
