import {ApolloClient, from, split, InMemoryCache} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {HttpLink} from '@apollo/client/link/http';
import {getMainDefinition} from '@apollo/client/utilities';
import {getToken, removeToken, saveToken} from '../storage';
import {REFRESH_TOKEN} from '../graphQL/mutations/refreshToken';

const httpLink = new HttpLink({
  uri: 'https://api-feedback.ddi-dev.com.ua/graphql',
});

const getNewToken = () => {
  return apolloClient
    .mutate({
      mutation: REFRESH_TOKEN,
      variables: {refreshToken: getToken()?.refresh_token},
    })
    .then(({data}) => {
      console.log(data.refreshToken);
      saveToken(data.refreshToken);
      return data.refreshToken.jwt_token;
    })
    .catch((e) => removeToken());
};

const refreshTokenLink = onError(
  ({graphQLErrors, networkError, response, operation, forward}) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (
          err.message === 'Signature has expired' ||
          err.message === 'jwt expired' ||
          err.message.includes('AnonymousUser')
        ) {
          // const oldHeaders = operation.getContext().headers;
          // operation.setContext({
          //   headers: {
          //     ...oldHeaders,
          //     authorization: getNewToken(),
          //   },
          // });
          // return forward(operation);
        }

        const {message, locations, path} = err;
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      }
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  },
);

const authLink = setContext(async (_, {headers}) => {
  const keys = await getToken();
  return {
    headers: {
      ...headers,
      authorization: keys ? `Bearer ${keys.jwt_token}` : '',
    },
  };
});

const cache = new InMemoryCache();

const link = split(
  // split based on operation type
  ({query}) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  authLink,
  httpLink,
);

// eslint-disable-next-line no-var
var apolloClient = new ApolloClient({
  link: from([refreshTokenLink, authLink, link]),
  cache,
});

export default apolloClient;
