import {ForgotPassword, ForgotPasswordProps} from 'modals';
import {ComponentClass, FC} from 'react';
import {
  AddFeedbackModal,
  AddFeedbackModalProps,
} from '../../modals/AddFeedback';
import {
  AddColleagueModal,
  AddColleagueModalProps,
} from '../../modals/AddColleague';
import {Success, FeedbackSuccessProps} from '../../modals/Success';
import {AddUser, AddUserModalProps} from '../../modals/AddUser';
import {ViewHistory, ViewHistoryProps} from '../../modals/ViewHistory';

export enum MODALS {
  ForgotPassword = 'forgotPassword',
  AddFeedback = 'addFeedback',
  AddColleague = 'addColleague',
  Success = 'success',
  AddUser = 'addUser',
  ViewHistory = 'viewHistory',
}

export type MODALS_TYPE = {
  [key in MODALS]: ComponentClass<any> | FC<any>;
};

export const AVAILABLE_MODALS: MODALS_TYPE = {
  [MODALS.ForgotPassword]: ForgotPassword,
  [MODALS.AddFeedback]: AddFeedbackModal,
  [MODALS.AddColleague]: AddColleagueModal,
  [MODALS.Success]: Success,
  [MODALS.AddUser]: AddUser,
  [MODALS.ViewHistory]: ViewHistory,
};

export type MODALS_PROPS = {
  [MODALS.ForgotPassword]: Omit<ForgotPasswordProps, 'open'>;
  [MODALS.AddFeedback]: Omit<AddFeedbackModalProps, 'open'>;
  [MODALS.AddColleague]: Omit<AddColleagueModalProps, 'open'>;
  [MODALS.Success]: Omit<FeedbackSuccessProps, 'open'>;
  [MODALS.AddUser]: Omit<AddUserModalProps, 'open'>;
  [MODALS.ViewHistory]: Omit<ViewHistoryProps, 'open'>;
};
