import React, {FC} from 'react';
import {AuthContext, AuthContextI} from './AuthContext';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {LOGIN} from '../../graphQL/mutations/login';
import {GET_SELF} from '../../graphQL/queries/me';
import {getToken, removeToken, saveToken} from '../../storage';
import client from '../../apolloClient';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {LoginRequestData} from '../../interfaces';

const AuthProvider: FC = ({children}) => {
  const [getSelf] = useLazyQuery(GET_SELF, {
    onCompleted: ({me}) => {
      me && history.push('/');
    },
  });
  const {data} = useQuery(GET_SELF);
  const token = getToken();
  const history = useHistory();

  const [login, {loading, error}] = useMutation(LOGIN, {
    onCompleted: async ({login}) => {
      if (login) {
        await saveToken(login);
        await getSelf();
      }
    },
    onError: () => toast.error('Unauthorized'),
  });

  const handleLogin = async ({email, password}: LoginRequestData) => {
    await login({
      variables: {
        email: email,
        password: password,
      },
    });
  };

  const handleLogout = async () => {
    await removeToken();
    await client.clearStore();

    history.push('/login');
  };

  const contextValues = (): AuthContextI['Combined'] => {
    return {
      authorized: !!token,
      currentUser: data?.me,
      loading,
      error,
      onLogin: handleLogin,
      onLogout: handleLogout,
    };
  };

  return (
    <AuthContext.Provider value={contextValues()}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
