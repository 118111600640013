import {gql} from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    userCreate(
      data: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      createdAt
      updatedAt
      id
      email
      firstName
      lastName
      isAdmin
    }
  }
`;
