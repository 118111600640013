import {gql} from '@apollo/client';

export const GET_COLLEAGUES = gql`
  query getColleagues($page: Int!, $per_page: Int!) {
    colleagues(page: $page, per_page: $per_page) {
      createdAt
      updatedAt
      id
      firstName
      lastName
      position
      feedbacks {
        createdAt
        id
        currentProjectScore
        currentProjectComment
        teamScore
        teamScoreComment
        generalComment
        status
      }
    }
  }
`;

export const GET_ALL_COLLEAGUES = gql`
  query getAllColleagues($page: Int!, $per_page: Int!, $search: String) {
    allColleagues(page: $page, per_page: $per_page, search: $search) {
      data {
        createdAt
        updatedAt
        id
        firstName
        lastName
        position
        user {
          id
          firstName
          lastName
          role
        }
        feedbacks {
          createdAt
          id
          currentProjectScore
          currentProjectComment
          teamScore
          teamScoreComment
          generalComment
          status
        }
      }
      pageInfo {
        total
        currentPage
      }
    }
  }
`;

export const GET_COLLEAGUE = gql`
  query colleague($colleagueId: ID!) {
    colleague(colleagueId: $colleagueId) {
      createdAt
      updatedAt
      id
      firstName
      lastName
      position
    }
  }
`;
